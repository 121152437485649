'use client';

import {
  Button,
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'ui';
import { SignInProvider } from '@/contexts/SignInContext';
import { loginUser } from '@/lib/loginUser';
import { signInIDP } from '@/lib/signInIDP';
import { useRouter } from 'next/navigation';
import React, { useState, useCallback } from 'react';
import {
  AuthenticationIDPContainer,
  PiramidLogo,
  AuthenticateForm,
  AuthenticateContainer,
  AuthenticateLogo,
  AuthenticateTitle,
  AuthenticateDescription,
  AuthenticationMoreOptions,
  AuthenticateTOS,
} from 'shared-components';
import { SignInWithGoogle } from './SignInWithGoogle';
import { SignInWithMicrosoft } from './SignInWithMicrosoft';

const LoginUserForm = ({
  setShowLocalStrategy,
}: {
  setShowLocalStrategy: (show: boolean) => void;
}) => {
  const handleBack = useCallback(() => setShowLocalStrategy(false), []);

  const router = useRouter();

  const handleSubmit = useCallback(
    (data: AuthenticateFormData) =>
      loginUser(data).then(() => router.push('/')),
    [],
  );

  const noop = () => {};

  return (
    <AuthenticateForm
      submit={{
        label: 'Iniciar sesión',
        handle: handleSubmit,
      }}
      context={'login'}
      back={{
        handle: handleBack,
        label: 'Volver al login',
      }}
      callbacks={{
        // insurer users are always verified (invitation email or google idp login)
        user_not_verified: noop,
      }}
    />
  );
};

export const Login = () => {
  const [showLocalStrategy, setShowLocalStrategy] = useState(false);
  const router = useRouter();

  return (
    <AuthenticateContainer>
      <AuthenticateLogo />

      <AuthenticateTitle>Inicia sesión.</AuthenticateTitle>

      <AuthenticateDescription>
        Automatiza la gestión de reclamos, mantente organizado y colabora con tu
        equipo.
      </AuthenticateDescription>

      {showLocalStrategy ? (
        <LoginUserForm setShowLocalStrategy={setShowLocalStrategy} />
      ) : (
        <>
          <SignInProvider>
            <AuthenticationIDPContainer>
              <SignInWithGoogle variant='white' small={true} />
              <AuthenticationMoreOptions
                handleContinueWithEmail={() => setShowLocalStrategy(true)}
              />
            </AuthenticationIDPContainer>
            <AuthenticateTOS />
          </SignInProvider>
        </>
      )}
    </AuthenticateContainer>
  );
};
